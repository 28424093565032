.scrollbarwidth::-webkit-scrollbar-thumb {
    background: #2E31B7;
  }
  
  .scrollbarwidth::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background: #ebebeb;
    display: block;
  }
